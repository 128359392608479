import React from 'react'
import styled from 'styled-components'
import BaseContent from '../Content'
import { LanguageSwitchFragmentFragment } from '../../../types/graphql-types'
import LanguageSwitch from '../LanguageSwitch'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Waves } from '@aristech/components'
import { graphql } from 'gatsby'
import GatsbyLink from '../Link'
import { FooterMenusFragmentFragment } from '../../../types/graphql-types'

interface Props {
  languages: LanguageSwitchFragmentFragment[]
  menus: FooterMenusFragmentFragment[]
}

const Wrapper = styled.footer`
  background: ${props => props.theme.secondary};
  margin-bottom: 0;
  margin-top: 5rem;
  padding-bottom: 1.5rem;
`
const Col = styled.div`
  -js-display: flex;
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
`
const Content = styled(BaseContent)`
  -js-display: flex;
  display: flex;
  max-width: ${props => props.theme.mediumBreakpoint}px;
  color: white;
  margin-bottom: 0;
  margin-top: 1.5rem;
  a,
  a:link,
  a:visited {
    color: white;
  }
  ${Col}:last-child {
    align-items: flex-end;
  }
  /* 2x2 */
  @media only screen and (max-width: 800px) {
    display: grid;
    grid-template-cols: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'col1 col2'
      'col3 col4';

    ${Col}:last-child {
      align-items: flex-start;
    }
  }
  /* list */
  @media only screen and (max-width: ${props =>
      props.theme.protraitBreakpoint}px) {
    -js-display: flex;
    display: flex;
    flex-direction: column;
    ${Col} {
      flex: none;
    }
    ${Col}:last-child {
      align-items: flex-start;
    }
  }
`
const CopyrightContent = styled(Content)`
  text-align: center;
  justify-content: center;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`
const Menu = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`
const MenuItem = styled.li`
  margin-bottom: 1em;
`
const MenuItemLink = styled(GatsbyLink)`
  color: white;
  text-decoration: none;
  &:visited {
    color: white;
  }
  &:hover {
    text-decoration: underline;
  }
`
const ColHeader = styled.h5`
  color: white;
  font-weight: bold;
  margin: 0;
  padding-bottom: 1.5rem;
`

export const query = graphql`
  fragment FooterMenusFragment on CMS_Menu {
    id
    Position
    Title
    Items {
      id
      Attention
      Title
      URL
      Icon
    }
  }
`

interface RenderMenuProps {
  menu?: FooterMenusFragmentFragment
}
const MenuList: React.FC<RenderMenuProps> = ({ menu }: RenderMenuProps) => {
  if (!menu) {
    return null
  }
  return (
    <>
      {menu.Title && <ColHeader>{menu.Title!}</ColHeader>}
      <Menu>
        {(menu.Items as any).map(({ id, Title, URL, Icon }: any) => (
          <MenuItem key={id}>
            <MenuItemLink to={URL}>
              {!!Icon && (
                <i
                  className={`flaticon-${Icon}`}
                  style={{ marginRight: '5px' }}
                />
              )}{' '}
              {Title}
            </MenuItemLink>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const Footer: React.FC<Props> = ({ languages, menus }: Props) => {
  const menu1 = menus.find(m => m.Position === 'Footer_1')
  const menu2 = menus.find(m => m.Position === 'Footer_2')
  const menu3 = menus.find(m => m.Position === 'Footer_3')
  return (
    <Wrapper>
      <Waves inverted />
      <Content>
        <Col style={{ gridArea: 'col1' }}>
          <MenuList menu={menu1} />
        </Col>
        <Col style={{ gridArea: 'col2' }}>
          <MenuList menu={menu2} />
        </Col>
        <Col style={{ gridArea: 'col3' }}>
          <MenuList menu={menu3} />
        </Col>
        <Col style={{ gridArea: 'col4' }}>
          <LanguageSwitch languages={languages} />
        </Col>
      </Content>
      <CopyrightContent>
        © {new Date().getFullYear()} Aristech GmbH -{' '}
        <FormattedMessage id="footer.copyright" />
      </CopyrightContent>
    </Wrapper>
  )
}

export default Footer
