import React from 'react'
// import BaseBackgroundImage from 'gatsby-background-image'
import GatsbyImage from 'gatsby-image'
// import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

interface Props {
  image?: any
  // When true this component should not show an own background
  heroIsVisible: boolean
  // children: React.ReactNode
}

const Image = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  // min-height: 90px;
  max-height: 66vh;
  min-height: 650px;
`
const Wrapper = styled.div`
  height: 90px;
  margin-bottom: -90px;
  position: relative;
  overflow: hidden;
  ${Image} {
    filter: blur(8px);
    transform: scale(1.2);
    transform-origin: center center;
    opacity: 0 !important;
    transition: opacity 0.25s linear !important;
  }
  &.show ${Image} {
    opacity: 1 !important;
  }
`

const HeroWrapper: React.FC<Props> = (props: Props) => {
  const { image, heroIsVisible /* , children */ } = props
  if (!image /* || heroIsVisible*/) {
    // return <>{children}</>
    return null
  }
  return (
    <Wrapper className={heroIsVisible ? '' : 'show'}>
      {/* <BackgroundImage
        fluid={image}
        style={{
          backgroundPosition: 'top center',
          backgroundAttachment: isMobile ? 'scroll' : 'fixed',
          height: '90px',
        }}
      > */}
      <Image fluid={image} style={{ maxWidth: 'none' }} />
      {/* {children} */}
      {/* </BackgroundImage> */}
    </Wrapper>
  )
}

export default HeroWrapper
