import React from 'react'
import styled, { keyframes } from 'styled-components'

export type State = 'none' | 'forbid' | 'allow'

interface Props {
  state: State
}

const Shake = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`

const Svg = styled.svg`
  .stop-sign {
    circle,
    line {
      fill: transparent;
      stroke: ${props => props.theme.danger};
      stroke-width: 2px;
      stroke-miterlimit: 10;
    }
  }
  .bite {
    fill: white;
  }
  .stop-sign,
  .bite {
    display: none;
  }
  &.allow {
    animation: ${Shake};
    animation-duration: 0.5s;
    .bite {
      display: block;
    }
  }
  &.forbid .stop-sign {
    display: block;
  }
`

const Cookie: React.FC<Props> = (props: Props) => {
  const { state } = props
  return (
    <Svg
      x="0px"
      y="0px"
      viewBox="0 0 44.3 44.3"
      className={`cookie ${state}`}
      role="presentation"
      aria-hidden="true"
    >
      <g>
        <circle fill="#D5A150" cx="22.2" cy="22.2" r="18.4" />
        <path
          fill="#AD712C"
          d="M33.6,7.8c2.5,3.1,4,7.1,4,11.4c0,10.1-8.2,18.4-18.4,18.4c-4.3,0-8.3-1.5-11.4-4c3.4,4.2,8.6,6.9,14.4,6.9
      c10.1,0,18.4-8.2,18.4-18.4C40.5,16.3,37.8,11.1,33.6,7.8z"
        />
        <path
          fill="#C98A2E"
          d="M13.8,14.2c-0.4,0-0.8-0.1-1-0.4c-1.6-1.6-1.6-4.2,0-5.7c0.8-0.8,1.8-1.2,2.9-1.2c1.1,0,2.1,0.4,2.9,1.2
      c0.6,0.6,0.6,1.5,0,2.1c-0.6,0.6-1.5,0.6-2.1,0c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.4,0.4-0.4,1.1,0,1.6
      c0.6,0.6,0.6,1.5,0,2.1C14.6,14.1,14.2,14.2,13.8,14.2z"
        />
        <circle fill="#674230" cx="15.6" cy="10.9" r="2.6" />
        <path
          fill="#7A5436"
          d="M13.1,10.7C13.1,10.7,13.1,10.7,13.1,10.7c-0.1,0.9,0.6,1.6,1.5,1.6c0.1,0,0.1,0,0.2,0
      c1.2-0.1,2.2-1.1,2.3-2.3c0,0,0,0,0,0c0.1-0.9-0.6-1.6-1.5-1.5c-0.1,0-0.1,0-0.2,0C14.1,8.5,13.2,9.4,13.1,10.7z"
        />
        <path
          fill="#C98A2E"
          d="M7.9,26c-0.4,0-0.8-0.1-1-0.4c-1.6-1.6-1.6-4.2,0-5.7c0.8-0.8,1.8-1.2,2.9-1.2s2.1,0.4,2.9,1.2
      c0.6,0.6,0.6,1.5,0,2.1c-0.6,0.6-1.5,0.6-2.1,0c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.4,0.4-0.4,1.1,0,1.6
      c0.6,0.6,0.6,1.5,0,2.1C8.6,25.9,8.3,26,7.9,26z"
        />
        <circle fill="#674230" cx="9.7" cy="22.7" r="2.6" />
        <path
          fill="#7A5436"
          d="M7.1,22.5C7.1,22.5,7.1,22.5,7.1,22.5c-0.1,0.9,0.6,1.6,1.5,1.6c0.1,0,0.1,0,0.2,0c1.2-0.1,2.2-1.1,2.3-2.3
      c0,0,0,0,0,0c0.1-0.9-0.6-1.6-1.5-1.5c-0.1,0-0.1,0-0.2,0C8.2,20.3,7.3,21.3,7.1,22.5z"
        />
        <path
          fill="#C98A2E"
          d="M13.1,33.4c-0.4,0-0.8-0.1-1-0.4c-1.6-1.6-1.6-4.2,0-5.7c0.8-0.8,1.8-1.2,2.9-1.2s2.1,0.4,2.9,1.2
      c0.6,0.6,0.6,1.5,0,2.1c-0.6,0.6-1.5,0.6-2.1,0c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.4,0.4-0.4,1.1,0,1.6
      c0.6,0.6,0.6,1.5,0,2.1C13.8,33.3,13.4,33.4,13.1,33.4z"
        />
        <circle fill="#674230" cx="14.9" cy="30.1" r="2.6" />
        <path
          fill="#7A5436"
          d="M12.3,29.9C12.3,29.9,12.3,29.9,12.3,29.9c-0.1,0.9,0.6,1.6,1.5,1.6c0.1,0,0.1,0,0.2,0
      c1.2-0.1,2.2-1.1,2.3-2.3c0,0,0,0,0,0c0.1-0.9-0.6-1.6-1.5-1.5c-0.1,0-0.1,0-0.2,0C13.4,27.7,12.4,28.7,12.3,29.9z"
        />
        <path
          fill="#C98A2E"
          d="M21.2,38.6c-0.4,0-0.8-0.1-1-0.4c-1.6-1.6-1.6-4.2,0-5.7c0.8-0.8,1.8-1.2,2.9-1.2c1.1,0,2.1,0.4,2.9,1.2
      c0.6,0.6,0.6,1.5,0,2.1c-0.6,0.6-1.5,0.6-2.1,0c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.4,0.4-0.4,1.1,0,1.6
      c0.6,0.6,0.6,1.5,0,2.1C21.9,38.5,21.6,38.6,21.2,38.6z"
        />
        <circle fill="#674230" cx="23" cy="35.3" r="2.6" />
        <path
          fill="#7A5436"
          d="M20.4,35C20.4,35.1,20.4,35.1,20.4,35c-0.1,0.9,0.6,1.6,1.5,1.6c0.1,0,0.1,0,0.2,0c1.2-0.1,2.2-1.1,2.3-2.3
      c0,0,0,0,0,0c0.1-0.9-0.6-1.6-1.5-1.5c-0.1,0-0.1,0-0.2,0C21.5,32.8,20.6,33.8,20.4,35z"
        />
        <path
          fill="#C98A2E"
          d="M22.7,17.9c-0.4,0-0.8-0.1-1-0.4c-1.6-1.6-1.6-4.2,0-5.7c0.8-0.8,1.8-1.2,2.9-1.2s2.1,0.4,2.9,1.2
      c0.6,0.6,0.6,1.5,0,2.1c-0.6,0.6-1.5,0.6-2.1,0c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.4,0.4-0.4,1.1,0,1.6
      c0.6,0.6,0.6,1.5,0,2.1C23.4,17.8,23,17.9,22.7,17.9z"
        />
        <circle fill="#674230" cx="24.5" cy="14.6" r="2.6" />
        <path
          fill="#7A5436"
          d="M21.9,14.4C21.9,14.4,21.9,14.4,21.9,14.4c-0.1,0.9,0.6,1.6,1.5,1.6c0.1,0,0.1,0,0.2,0
      c1.2-0.1,2.2-1.1,2.3-2.3c0,0,0,0,0,0c0.1-0.9-0.6-1.6-1.5-1.5c-0.1,0-0.1,0-0.2,0C23,12.2,22,13.1,21.9,14.4z"
        />
        <path
          fill="#C98A2E"
          d="M31.5,31.2c-0.4,0-0.8-0.1-1-0.4c-1.6-1.6-1.6-4.2,0-5.7c0.8-0.8,1.8-1.2,2.9-1.2s2.1,0.4,2.9,1.2
      c0.6,0.6,0.6,1.5,0,2.1c-0.6,0.6-1.5,0.6-2.1,0c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.4,0.4-0.4,1.1,0,1.6
      c0.6,0.6,0.6,1.5,0,2.1C32.3,31.1,31.9,31.2,31.5,31.2z"
        />
        <circle fill="#674230" cx="33.4" cy="27.9" r="2.6" />
        <path
          fill="#7A5436"
          d="M30.8,27.7C30.8,27.7,30.8,27.7,30.8,27.7c-0.1,0.9,0.6,1.6,1.5,1.6c0.1,0,0.1,0,0.2,0
      c1.2-0.1,2.2-1.1,2.3-2.3c0,0,0,0,0,0c0.1-0.9-0.6-1.6-1.5-1.5c-0.1,0-0.1,0-0.2,0C31.9,25.5,30.9,26.4,30.8,27.7z"
        />
        <path
          fill="#C98A2E"
          d="M30.8,17.2c-0.4,0-0.8-0.1-1-0.4c-1.6-1.6-1.6-4.2,0-5.7c0.8-0.8,1.8-1.2,2.9-1.2c1.1,0,2.1,0.4,2.9,1.2
      c0.6,0.6,0.6,1.5,0,2.1c-0.6,0.6-1.5,0.6-2.1,0c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.4,0.4-0.4,1.1,0,1.6
      c0.6,0.6,0.6,1.5,0,2.1C31.6,17,31.2,17.2,30.8,17.2z"
        />
        <circle fill="#674230" cx="32.6" cy="13.9" r="2.6" />
        <path
          fill="#7A5436"
          d="M30.1,13.6C30,13.6,30,13.6,30.1,13.6c-0.1,0.9,0.6,1.6,1.5,1.6c0.1,0,0.1,0,0.2,0c1.2-0.1,2.2-1.1,2.3-2.3
      c0,0,0,0,0,0c0.1-0.9-0.6-1.6-1.5-1.5c-0.1,0-0.1,0-0.2,0C31.1,11.4,30.2,12.4,30.1,13.6z"
        />
        <path
          fill="#C98A2E"
          d="M19.7,26c-0.4,0-0.8-0.1-1-0.4c-1.6-1.6-1.6-4.2,0-5.7c0.8-0.8,1.8-1.2,2.9-1.2c1.1,0,2.1,0.4,2.9,1.2
      c0.6,0.6,0.6,1.5,0,2.1c-0.6,0.6-1.5,0.6-2.1,0c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.4,0.4-0.4,1.1,0,1.6
      c0.6,0.6,0.6,1.5,0,2.1C20.5,25.9,20.1,26,19.7,26z"
        />
        <circle fill="#674230" cx="21.5" cy="22.7" r="2.6" />
        <path
          fill="#7A5436"
          d="M19,22.5C19,22.5,19,22.5,19,22.5c-0.1,0.9,0.6,1.6,1.5,1.6c0.1,0,0.1,0,0.2,0c1.2-0.1,2.2-1.1,2.3-2.3
      c0,0,0,0,0,0c0.1-0.9-0.6-1.6-1.5-1.5c-0.1,0-0.1,0-0.2,0C20.1,20.3,19.1,21.3,19,22.5z"
        />
        <g>
          <circle fill="#AD712C" cx="13.1" cy="24.9" r="0.6" />
          <circle fill="#AD712C" cx="16.8" cy="24.9" r="0.6" />
          <circle fill="#AD712C" cx="14.9" cy="21.6" r="0.6" />
          <circle fill="#AD712C" cx="8.3" cy="28.2" r="0.6" />
          <circle fill="#AD712C" cx="15.7" cy="36.4" r="0.6" />
          <circle fill="#AD712C" cx="17.9" cy="33.4" r="0.6" />
          <circle fill="#AD712C" cx="21.6" cy="29" r="0.6" />
          <circle fill="#AD712C" cx="27.5" cy="26.8" r="0.6" />
          <circle fill="#AD712C" cx="26" cy="28.2" r="0.6" />
          <circle fill="#AD712C" cx="28.2" cy="30.5" r="0.6" />
          <circle fill="#AD712C" cx="28.2" cy="34.9" r="0.6" />
          <circle fill="#AD712C" cx="36.4" cy="19.4" r="0.6" />
          <circle fill="#AD712C" cx="34.2" cy="34.2" r="0.6" />
          <circle fill="#AD712C" cx="23.1" cy="6.1" r="0.6" />
          <circle fill="#AD712C" cx="9.8" cy="12.7" r="0.6" />
          <circle fill="#AD712C" cx="6.8" cy="17.2" r="0.6" />
          <circle fill="#AD712C" cx="15.3" cy="18.3" r="0.6" />
          <circle fill="#AD712C" cx="17.5" cy="17.2" r="0.6" />
          <circle fill="#AD712C" cx="15.3" cy="16.1" r="0.6" />
          <circle fill="#AD712C" cx="25.7" cy="21.6" r="0.6" />
          <circle fill="#AD712C" cx="20.9" cy="10.5" r="0.6" />
          <circle fill="#AD712C" cx="27.9" cy="17.2" r="0.6" />
          <circle fill="#AD712C" cx="29" cy="20.5" r="0.6" />
          <circle fill="#AD712C" cx="31.2" cy="20.5" r="0.6" />
          <circle fill="#AD712C" cx="31.2" cy="22.7" r="0.6" />
          <circle fill="#AD712C" cx="27.1" cy="9.4" r="0.6" />
          <circle fill="#AD712C" cx="29.4" cy="8.3" r="0.6" />
        </g>
      </g>
      <path
        className="bite"
        d="M39.8,15.3c-1,1.3-2.9,1.5-4.2,0.4c0,0-0.1-0.1-0.1-0.1c-1,0.7-2.4,0.6-3.5-0.2
    c-0.2-0.2-0.3-0.3-0.5-0.5c-0.8,0.2-1.7,0-2.5-0.6c-0.7-0.6-1.1-1.4-1.1-2.3c-0.2-0.1-0.4-0.2-0.6-0.4c-1-0.8-1.4-2.2-0.9-3.3
    c-0.1,0-0.1-0.1-0.1-0.1c-1.3-1-1.5-2.9-0.4-4.2l2.5-3.1l14,11.3L39.8,15.3z"
      />
      <g className="stop-sign">
        <circle cx="22.2" cy="22.2" r="21" />
        <line x1="7.4" y1="7.2" x2="37.1" y2="36.9" />
      </g>
    </Svg>
  )
}

export default Cookie
