import React from 'react'
import { graphql } from 'gatsby'
import { LanguageSwitchFragmentFragment } from '../../../types/graphql-types'
import styled from 'styled-components'
import { useIntl, changeLocale } from 'gatsby-plugin-intl'
import Img from 'gatsby-image'
import useClickAway from 'react-use/lib/useClickAway'
import useKey from 'react-use/lib/useKey'

const Dropdown = styled.div`
  -js-display: flex;
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 100%;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.1s ease-in-out;
`
const DropdownItem = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  padding: ${props => props.theme.scale ** -3}em 1em;
  transition: background 0.25s linear;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`
const Button = styled.div`
  display: inline-flex;
  align-items: center;
  padding: ${props => props.theme.scale ** -3}em 1em;
  background: rgba(0, 0, 0, 0.2);
  transition: background 0.25s linear;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 8px 6px;
    border-color: transparent transparent #fff transparent;
    margin-left: ${props => props.theme.scale ** -3}em;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  &.open {
    background: rgba(0, 0, 0, 0.4);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    &:after {
      transform: scaleY(-1);
    }
    ${Dropdown} {
      transform: scaleY(1);
    }
  }
`
const Label = styled.div`
  margin-left: ${props => props.theme.scale ** -3}em;
`
const Flag = styled(Img)`
  display: inline-block;
  flex-shrink: 0;
  flex-grow: 0;
  object-fit: contain;
`

export const query = graphql`
  fragment LanguageSwitchFragment on CMS_Language {
    Title
    locale
    Flag {
      id
      url
      urlSharp {
        childImageSharp {
          fixed(fit: COVER, width: 24, height: 16) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

interface Props {
  languages: LanguageSwitchFragmentFragment[]
}

const LanguageSwitch: React.FC<Props> = (props: Props) => {
  const { languages } = props
  const [open, setOpen] = React.useState(false)
  const intl = useIntl()
  const ref = React.useRef(null)
  const close = () => {
    setOpen(false)
  }
  useClickAway(ref, close)
  useKey('Escape', close)
  const current = languages.find(l => l.locale === intl.locale) || languages[0]
  return (
    <Button
      className={open ? 'open' : 'closed'}
      onClick={() => setOpen(!open)}
      ref={ref}
    >
      <Flag fixed={current.Flag?.urlSharp?.childImageSharp?.fixed as any} />
      <Label>{current.Title}</Label>
      <Dropdown>
        {languages
          .filter(l => l.locale !== current.locale)
          .map(l => (
            <DropdownItem
              key={l.locale!}
              onClick={() => changeLocale(l.locale!)}
            >
              <Flag
                fixed={l.Flag?.urlSharp?.childImageSharp?.fixed as any}
                alt={l.Title}
                title={l.Title}
              />
              <Label>{l.Title}</Label>
            </DropdownItem>
          ))}
      </Dropdown>
    </Button>
  )
}

export default LanguageSwitch
