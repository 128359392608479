import styled from 'styled-components'

const Header = styled.h2`
  font-weight: bold;
  text-align: center;
  padding: 1.5rem 0;
  .subtitle {
    display: block;
    color: #ccc;
    font-weight: normal;
    font-size: 0.8em;
  }
  /* IE 8+ */
  @media screen\\0 {
    position: relative;
  }
  @media only screen and (max-width: 350px) {
    hyphens: auto;
  }
`

export default Header
