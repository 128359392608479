/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import MainMenu from '../MainMenu'
import Footer from '../Footer'
import {
  MainMenuFragmentFragment,
  LanguageSwitchFragmentFragment,
  FooterMenusFragmentFragment,
} from '../../../types/graphql-types'
import styled from 'styled-components'
import CookieConsent from '../CookieConsent'

if (typeof window !== 'undefined') {
  // eslint-disable-next-line
  const smoothScroll = require('smooth-scroll')
  // eslint-disable-next-line global-require
  smoothScroll('a[href*="#"]')
}

const ContentStack = styled.main`
  & > * + * {
    margin-top: 3rem;
  }
`

interface Props {
  children: React.ReactNode
  hideMenubar?: boolean
  transparentMenubar?: boolean
  burgerMenubar?: boolean
  forcePinMenubar?: boolean
  heroImageMenubar?: any
  heroIsVisible?: boolean
  darkMenu?: boolean
  menuData: MainMenuFragmentFragment
  footerMenuData: FooterMenusFragmentFragment[]
  languageSwitchData: LanguageSwitchFragmentFragment[]
  ref?: React.MutableRefObject<null>
}

const Layout: React.FC<Props> = ({
  children,
  menuData,
  transparentMenubar,
  burgerMenubar,
  forcePinMenubar,
  heroImageMenubar,
  darkMenu,
  heroIsVisible,
  languageSwitchData,
  footerMenuData,
}: Props) => {
  return (
    <>
      <MainMenu
        data={menuData}
        transparent={transparentMenubar}
        burger={burgerMenubar}
        forcePin={forcePinMenubar}
        heroImage={heroImageMenubar}
        darkMenu={darkMenu}
        heroIsVisible={heroIsVisible}
      />
      <ContentStack>{children}</ContentStack>
      <Footer languages={languageSwitchData} menus={footerMenuData} />
      <CookieConsent />
    </>
  )
}

export default Layout
