import React from 'react'
import styled, { CSSProperties } from 'styled-components'

const Bun = styled.div<{ color: string }>`
  height: 3px;
  border-radius: 3px;
  background: ${props => props.color};
  transform-origin: center;
  transition: transform 0.25s ease-in-out;
  flex-grow: 0;
  flex-shrink: 0;
`

const Patty = styled.div<{ color: string }>`
  height: 3px;
  border-radius: 3px;
  background: ${props => props.color};
  transition: opacity 0.25s ease-in-out;
  flex-grow: 0;
  flex-shrink: 0;
`

const Package = styled.div`
  -js-display: flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40px;
  height: 31px;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  &.open ${Bun}.top {
    transform: translateY(9px) rotate(45deg);
  }
  &.open ${Patty} {
    opacity: 0;
  }
  &.open ${Bun}.bottom {
    transform: translateY(-9px) rotate(-45deg);
  }

  &.dark {
    ${Bun}, ${Patty} {
      background: #333;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 3px white;
    border-radius: 3px;
    box-sizing: border-box;
    transform-origin: center;
    opacity: 0;
    transition: transform 0.25s linear, opacity 0.25s linear;
  }
  &:focus {
    outline: 0;
    &:before {
      transform: scale(1.2);
      opacity: 0.3;
    }
  }
`
const HiddenLabel = styled.div`
  display: none;
`

interface Props {
  open: boolean
  onToggle: (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>
  ) => void
  color?: string
  style?: CSSProperties
  id?: string
  // Used for aria -> which menu is being controlled
  menuId?: string
  hiddenLabel?: string // Screen reader label
  dark?: boolean
}

const CheeseBurger: React.FC<Props> = ({
  open,
  onToggle,
  color = 'white',
  style,
  id,
  menuId,
  hiddenLabel,
  dark,
}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const classNames = open ? ['open'] : ['closed']
  if (dark) {
    classNames.push('dark')
  }
  return (
    <Package
      ref={ref}
      id={id}
      className={classNames.join(' ')}
      onClick={e => {
        // Remove focus
        const { current } = ref
        current && current.blur()
        onToggle(e)
      }}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.which === 32 /* Space */) {
          e.preventDefault()
          onToggle(e)
        }
      }}
      style={style}
      aria-expanded={open}
      aria-haspopup="true"
      aria-controls={menuId}
      tabIndex={0}
    >
      <Bun className={'top'} color={color} />
      <Patty color={color} />
      <Bun className={'bottom'} color={color} />
      <HiddenLabel>{hiddenLabel}</HiddenLabel>
    </Package>
  )
}

export default CheeseBurger
