import React from 'react'
import styled from 'styled-components'
import GatsbyLink from '../Link'
import Logo from '../../assets/logo.svg'
import Burger from '../Burger'
import { Button, filterButtonProps } from '@aristech/components'
import mightyBlue from '../../utils/mightyBlue'
import { useIntl } from 'gatsby-plugin-intl'
import {
  MainMenuFragmentFragment,
  Cms_ComponentMenuMenuItem,
} from '../../../types/graphql-types'
// import useTraceUpdate from '../../utils/useTraceUpdate'

interface Props {
  darkMenu: boolean
  burger: boolean
  isSmall: boolean
  open: boolean
  onOpen: (open: boolean) => void
  data: MainMenuFragmentFragment
}

const Link = filterButtonProps(GatsbyLink)

const Wrapper = styled.nav`
  -js-display: flex;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 2em;
  max-width: ${props => props.theme.mediumBreakpoint}px;
  height: 100%;
`
const LogoWrapper = styled(GatsbyLink)`
  -js-display: flex;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  z-index: 101;
  svg {
    height: 2em;
    path {
      fill: white;
    }
    .logo-text {
      transition: opacity 0.25s linear;
    }
  }
  &:after {
    content: '';
    background: white;
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    opacity: 0;
    transform: scaleX(0);
    transition: opacity 0.25s linear, transform 0.25s ease-out;
  }
  &:focus {
    outline: none;
    &:after {
      opacity: 0.5;
      transform: scaleX(1);
    }
    &:hover:after {
      opacity: 1;
    }
  }
  @media only screen and (max-width: ${props =>
      props.theme.smallBreakpoint}px) {
    /* svg .logo-text {
      opacity: 0;
    } */
    svg {
      height: 1.5em;
    }
    /*
    &:after {
      width: 53px;
    }*/
  }
  /* IE 8+ */
  @media screen\\0 {
    display: block;
    padding-top: 25px;
  }
`
const Menu = styled.div`
  -js-display: flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const MenuList = styled.ul`
  -js-display: flex;
  display: flex;
  list-style-type: none;
  padding: 0 1em;
  justify-content: space-between;
  align-items: center;
  transform-origin: right;
  overflow: visible;
  // &.animated {
  //   transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  // }
  > * + * {
    margin-left: ${props => props.theme.scale ** 3}em;
    @media only screen and (max-width: ${props =>
        props.theme.smallBreakpoint}px) {
      margin-left: 0;
      margin-top: ${props => props.theme.scale ** 4}em;
    }
  }
  &.closed {
    transform: scaleX(0);
    opacity: 0;
  }
  @media only screen and (max-width: ${props =>
      props.theme.smallBreakpoint}px) {
    margin: 0;
    padding: 0 0 1em 0;
    // padding: 90px 0 1em 0;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    ${mightyBlue}
  }
`
const MenuItem = styled.li`
  -js-display: flex;
  display: flex;
  align-items: stretch;
`
export const MenuLink = styled(GatsbyLink)`
  height: 2.5em;
  line-height: 2.5em;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.scale ** -11}em;
  display: block;
  position: relative;
  &:link,
  &:visited {
    color: white;
    text-decoration: none;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background: white;
    transform: scaleX(0);
    opacity: 0;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  }
  &:focus {
    outline: none;
    &:after {
      transform: scaleX(1);
      opacity: 0.5;
    }
  }
  &:hover:after {
    transform: scaleX(1);
    opacity: 1;
  }
  @media only screen and (max-width: ${props =>
      props.theme.protraitBreakpoint}px) {
    letter-spacing: ${props => props.theme.scale ** -25}em;
  }
`
const AttentionButtons = styled.div`
  -js-display: flex;
  display: flex;
  align-items: center;
  padding-left: 2em;
  > * + * {
    margin-left: 1em;
  }
`

const MMenu: React.FC<Props> = (props: Props) => {
  const { darkMenu, burger, isSmall, open, onOpen, data } = props

  const items = (data.Items ??
    (([] as Cms_ComponentMenuMenuItem[]) as any)) as Cms_ComponentMenuMenuItem[]
  const menuListRef = React.useRef<HTMLUListElement>(null)
  const intl = useIntl()

  const listItems = items.filter(({ Attention }) => !Attention)
  const attentionItems = items.filter(({ Attention }) => Attention)

  return (
    <Wrapper>
      <LogoWrapper
        to={'/'}
        aria-label={intl.formatMessage({ id: 'home.title' })}
      >
        <Logo />
      </LogoWrapper>
      <Menu>
        <MenuList
          ref={menuListRef}
          id="main-menu"
          className={open || (!isSmall && !burger) ? 'open' : 'closed'}
          role="menu"
          aria-labelledby={isSmall || burger ? 'main-menu-burger' : undefined}
          aria-label={
            !(isSmall || burger)
              ? intl.formatMessage({ id: 'main_menu' })
              : undefined
          }
        >
          {listItems.map(({ id, Title, URL }, idx) => (
            <MenuItem role="none" key={id || idx}>
              <MenuLink
                tabIndex={open || (!isSmall && !burger) ? 0 : -1}
                role="menuitem"
                to={URL || ''}
                id={idx === 0 ? 'first-main-menu-link' : ''}
                onClick={() => onOpen(false)}
              >
                {Title}
              </MenuLink>
            </MenuItem>
          ))}
          {isSmall &&
            attentionItems.map(({ id, Title, URL }, idx) => (
              <MenuItem role="none" key={id || idx}>
                <MenuLink
                  tabIndex={open || (!isSmall && !burger) ? 0 : -1}
                  role="menuitem"
                  to={URL || ''}
                  onClick={() => onOpen(false)}
                >
                  {Title}
                </MenuLink>
              </MenuItem>
            ))}
        </MenuList>
        {(isSmall || burger) && (
          <Burger
            open={open}
            dark={!open && darkMenu}
            onToggle={e => {
              const menuList = menuListRef.current
              if (menuList) {
                menuList.style.transition =
                  'transform 0.25s ease-in-out, opacity 0.25s ease-in-out'
                setTimeout(() => {
                  menuList.style.transition = 'none'
                }, 300)
              }
              onOpen(!open)
              // focus first element for keyboard events
              const el = document.getElementById('first-main-menu-link')
              if (!open && e.type === 'keydown' && el) {
                el.focus()
              }
            }}
            color={'white'}
            style={{ zIndex: 102 }}
            menuId="main-menu"
            id="main-menu-burger"
            hiddenLabel={intl.formatMessage({ id: 'main_menu' })}
          />
        )}
        {!isSmall && (
          <AttentionButtons>
            {attentionItems.map(({ id, Title, URL }, idx) => (
              <Button
                as={Link}
                to={URL || ''}
                key={id || idx}
                color={darkMenu ? 'secondary' : 'none'}
                style={{
                  display: 'inline-block',
                  borderColor: 'transparent',
                }}
              >
                {Title}
              </Button>
            ))}
          </AttentionButtons>
        )}
      </Menu>
    </Wrapper>
  )
}

export default React.memo(MMenu)
