import styled from 'styled-components'

const Content = styled.section`
  max-width: 850px;
  padding: 0 3rem;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: ${props =>
      props.theme.smallBreakpoint}px) {
    padding: 0 1.5rem;
  }
`

export default Content
