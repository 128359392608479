import React from 'react'
import { Link as GatsbyLink } from 'gatsby-plugin-intl'
// import { Link as GatsbyLink } from 'gatsby'
import { GatsbyLinkProps } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link: React.FC<GatsbyLinkProps<any>> = (props: GatsbyLinkProps<any>) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const { children, to, activeClassName, partiallyActive, ...rest } = props
  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...(rest as any)}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <OutboundLink
      href={to}
      {...(rest as any)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </OutboundLink>
  )
}

export default Link
