import { css } from 'styled-components'

const mightyBlue = css`
  background: repeating-linear-gradient(
      30.5deg,
      rgba(0, 0, 0, 0.07),
      rgba(0, 0, 0, 0.07) 1px,
      rgba(0, 0, 0, 0) 1px,
      rgba(0, 0, 0, 0) 100px
    ),
    repeating-linear-gradient(
      -30.5deg,
      rgba(0, 0, 0, 0.07),
      rgba(0, 0, 0, 0.07) 1px,
      rgba(0, 0, 0, 0) 1px,
      rgba(0, 0, 0, 0) 100px
    ),
    linear-gradient(
      60deg,
      ${props => props.theme.gradientStart} 0%,
      ${props => props.theme.gradientStop} 100%
    );
`

export default mightyBlue
