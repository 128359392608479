import React from "react";
import styled from "styled-components";
import { injectIntl, IntlShape } from "gatsby-plugin-intl";

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.danger};
  padding: 1em;
  margin: 1em 0;
  border: solid 1px #ccc;
  border-radius: 3px;
`;

interface Props {
  children: React.ReactNode;
  intl: IntlShape;
  fallback?: React.ReactNode;
}
interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(/* error: Error */) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(/* error: Error | null, errorInfo: object */) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const { fallback } = this.props;
      if (fallback) {
        return fallback;
      }
      return (
        <ErrorMessage>
          {this.props.intl.formatMessage({ id: "component_load_error" })}
        </ErrorMessage>
      );
    }

    return this.props.children;
  }
}

export default injectIntl(ErrorBoundary);
