import { graphql } from 'gatsby'
// import GatsbyLink from '../Link'
import React, { CSSProperties } from 'react'
import styled, { ThemeContext, createGlobalStyle } from 'styled-components'
import useMedia from 'use-media'
import { MainMenuFragmentFragment } from '../../../types/graphql-types'
import useKey from 'react-use/lib/useKey'
import Headroom from 'react-headroom'
import HeroWrapper from './heroWrapper'
import mightyBlue from '../../utils/mightyBlue'
import Menu, { MenuLink } from './Menu'

interface Props {
  transparent?: boolean
  burger?: boolean
  forcePin?: boolean
  heroImage?: any
  darkMenu?: boolean
  heroIsVisible?: boolean
  data: MainMenuFragmentFragment
}

const Wrapper = styled.header`
  ${mightyBlue};
  height: 90px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.25s linear;
  // margin-bottom: 2em;
  &.transparent {
    background: transparent;
    box-shadow: none;
    margin-bottom: 0;
    // margin-bottom: -90px;
    ${MenuLink} {
      color: white;
      &:after {
        background: white;
      }
    }
    :not(.small).dark ${MenuLink} {
      color: #333;
      &:after {
        background: #333;
      }
    }
  }
`
const PreventScrolling = createGlobalStyle`
  body {
    overflow: hidden;
  }
  .headroom {
    position: fixed;
    transform: none !important;
  }
`

export const query = graphql`
  fragment MainMenuFragment on CMS_Menu {
    id
    Position
    Items {
      id
      Attention
      Title
      URL
    }
  }
`

const MainMenu: React.FC<Props> = ({
  transparent,
  burger = false,
  forcePin,
  heroImage,
  darkMenu = false,
  heroIsVisible = true,
  data,
}: Props) => {
  const theme = React.useContext(ThemeContext)
  const [open, setOpen] = React.useState(false)
  useKey('Escape', () => setOpen(false))
  const isSmall = useMedia({ maxWidth: theme.smallBreakpoint })

  const classNames: Array<string> = []
  if (transparent) {
    classNames.push('transparent')
  }
  if (isSmall) {
    classNames.push('small')
  }
  if (darkMenu) {
    classNames.push('dark')
  }
  const headRoomStyle: CSSProperties = { zIndex: 1000 }
  if (forcePin || (open && isSmall)) {
    headRoomStyle.position = 'fixed'
    headRoomStyle.transform = 'none'
  }

  return (
    <Headroom style={headRoomStyle} disable={forcePin}>
      {open && isSmall && <PreventScrolling />}
      <Wrapper className={classNames.join(' ')}>
        <HeroWrapper image={heroImage} heroIsVisible={heroIsVisible} />
        <Menu
          darkMenu={darkMenu}
          burger={burger}
          isSmall={isSmall}
          open={open}
          onOpen={setOpen}
          data={data}
        />
      </Wrapper>
    </Headroom>
  )
}

export default MainMenu
